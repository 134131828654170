import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function Logs() {

    return (
        <Grid container columns={2} textAlign={"left"}>
            <Grid.Row>
                <Grid.Column>
                    <List>
                        <List.Item>
                            <Link to="/log/audit">Audit log</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/log/cartridge">Cartridge Load log</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/log/dispense">Dispense log</Link>
                        </List.Item>
                        <List.Item>
                            <Link to="/log/orders">Order log</Link>
                        </List.Item>
                    </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}