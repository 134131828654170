import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Grid, Loader, Message } from "semantic-ui-react";
import { useUser } from "../../context/auth";
import { useMedicineConfig } from "../../context/medicine_configuration";
import { saveCartridge } from "../../services/api";

export function CartridgeTypeFormPage({ match }) {
    return <CartridgeTypeForm id={match.params.id} />;
}

export function CartridgeTypeForm({ id }) {
    const { user } = useUser();
    const history = useHistory();
    const [message, setMessage] = useState();

    const [cartridge, setCartridge] = useState({});
    const [validation, setValidation] = useState({});
    
    const { cartridgesMap, medicine, refresh } = useMedicineConfig();

    useEffect(() => {
        if (cartridgesMap) {
            const cartridge = cartridgesMap[id];
            if (cartridge) {
                setCartridge(cartridge);
            }
        }
    }, [cartridgesMap, id]);

    function update(field, value) {
        const u = { ...cartridge };
        u[field] = value;
        setCartridge(u);
    }

    function submit() {
        let validationErrors = {};

        if (!cartridge.id) {
            validationErrors.id = true;
        }
        if (!cartridge.medicine_type_id) {
            validationErrors.medicine_type_id = true;
        }
        if (!cartridge.capacity) {
            validationErrors.capacity = true;
        }
        if (!cartridge.rows) {
            validationErrors.rows = true;
        }
        if (cartridge.capacity && cartridge.rows) {
            const perRow = cartridge.capacity / cartridge.rows;
            if (!Number.isInteger(perRow)) {
                validationErrors.rows = "Capacity must be divisible by number of rows"
            }
        }

        setValidation(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            saveCartridge(user.token, cartridge, id !== undefined)
                .then(() => {
                    refresh();
                    history.push("/config");
                }).catch(res => {
                    setMessage(res.message);
                });
        }
    }

    if (!medicine) {
        return <Loader active />;
    }

    return (
        <Grid container columns={1} textAlign={"left"}>
            <Form>
                <Form.Field error={validation.id}>
                    <label>id</label>
                    <Form.Input
                        value={cartridge.id}
                        onChange={evt => update("id", evt.target.value)}
                        placeholder='id'
                    />
                </Form.Field>
                <Form.Field error={validation.medicine_type_id}>
                    <label>Medicine type</label>
                    <Dropdown
                        placeholder='Medicine type'
                        fluid
                        selection
                        value={cartridge.medicine_type_id}
                        options={medicine.map(it => ({ key: it.id, value: it.id, text: `${it.name}, ${it.volume_ml} ml (${it.container_type})` }))}
                        onChange={(_, data) => update("medicine_type_id", data.value)}
                    />
                </Form.Field>
                <Form.Field error={validation.capacity}>
                    <label>Capacity</label>
                    <Form.Input
                        value={cartridge.capacity}
                        onChange={evt => update("capacity", evt.target.value)}
                        placeholder='Capacity'
                    />
                </Form.Field>
                <Form.Field error={validation.rows !== undefined}>
                    <label>Rows</label>
                    <Form.Input
                        error={validation.rows}
                        value={cartridge.rows}
                        onChange={evt => update("rows", evt.target.value)}
                        placeholder='rows'
                    />
                </Form.Field>
                <Button type='submit' onClick={submit}>Submit</Button>
                {message && <Message negative>{message}</Message>}
            </Form>
        </Grid>
    )
}