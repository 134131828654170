import React from 'react';
import { getOrderLogUrl } from '../../services/api';
import { Table } from 'semantic-ui-react';
import LogTable from './LogTable';

export default function OrderLog() {
    return (
        <LogTable
            title="Order log"
            url={getOrderLogUrl()}
            headers={["ID", "User", "Machine", "Medicine", "Requested", "Dispensed", "Timestamp"]}
            populateBody={data => data.map(it => (
                <Table.Row key={it.id}>
                    <Table.Cell>{it.id}</Table.Cell>
                    <Table.Cell>{it.user_id}</Table.Cell>
                    <Table.Cell>{it.machine_id}</Table.Cell>
                    <Table.Cell>{it.medicine_type_id}</Table.Cell>
                    <Table.Cell>{it.requested}</Table.Cell>
                    <Table.Cell>{it.dispensed}</Table.Cell>
                    <Table.Cell>{it.timestamp}</Table.Cell>
                </Table.Row>
            ))}
        />);
}