import React from "react";
import { Card, Loader } from "semantic-ui-react";
import useSWR from "swr";
import { useUser } from "../../context/auth";
import { getMachineListUrl } from "../../services/api";
import { fetchWithToken } from "../../utils/fetch";
import Machine from "./Machine";

export function MachineList() {
    const { user } = useUser();

    const { data: machinesObject } = useSWR(getMachineListUrl(), fetchWithToken(user.token));

    if (!machinesObject) {
        return <Loader active />;
    }

    const { machines } = machinesObject;

    return (
        <Card.Group>
            {machines.map(it => <Machine machine={it} key={it.id} />)}
        </Card.Group>
    )
}