import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Grid, Loader, Message } from "semantic-ui-react";
import { useUser } from "../../context/auth";
import { useMedicineConfig } from "../../context/medicine_configuration";
import { getMedicineImageUrl, saveMedicine } from "../../services/api";
import AuthenticatedImage from "../AuthenticatedImage";

export function MedicineTypeFormPage({ match }) {
    return <MedicineTypeForm id={match.params.id} />;
}

export function MedicineTypeForm({ id }) {
    const { user } = useUser();
    const history = useHistory();
    const [message, setMessage] = useState();

    const [medicine, setMedicine] = useState({});
    const [validation, setValidation] = useState({});
    const [uploadFile, setUploadFile] = useState();

    const { medicineMap, refresh } = useMedicineConfig();

    useEffect(() => {
        if (medicineMap) {
            const medicine = medicineMap[id];
            if (medicine) {
                setMedicine(medicine);
            }
        }
    }, [medicineMap, id]);

    function update(field, value) {
        const u = { ...medicine };
        u[field] = value;
        setMedicine(u);
    }

    function submit() {
        let validationErrors = {};

        if (!medicine.id) {
            validationErrors.id = true;
        }
        if (!medicine.name) {
            validationErrors.name = true;
        }
        if (!medicine.container_type) {
            validationErrors.container_type = true;
        }
        if (!medicine.volume_ml) {
            validationErrors.volume_ml = true;
        }
        if (!medicine.limit_amount) {
            validationErrors.limit_amount = true;
        }
        if (!medicine.limit_period_hours) {
            validationErrors.limit_period_hours = true;
        }

        setValidation(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            saveMedicine(user.token, medicine, id !== undefined)
                .then(() => {
                    if (uploadFile) {                        
                        uploadNewImage().then(_ => {
                            refresh();
                            history.push("/config");
                        }).catch(e => {                             
                            setMessage(`Medicine saved but the image could not be uploaded - ${JSON.stringify(e)}`);
                        });
                    } else {
                        refresh();
                    }
                }).catch(res => {
                    setMessage(res.message);
                });
        }
    }

    const uploadNewImage = () => {
        const data = new FormData();
        data.append('image', uploadFile);

        return fetch(getMedicineImageUrl(medicine.id), {
                method: 'PUT',
                headers: {"Authorization": `Bearer ${user.token}`},
                body: data
            }).then(res => {
                if (res.ok) {
                    return res;
                } else {
                    return res.json().then(err => {
                        throw err;
                    })
                }
            }); 
    };

    if (!medicine) {
        return <Loader active />;
    }

    return (
        <Grid container columns={1} textAlign={"left"}>
            <Form>
                <Form.Field error={validation.id}>
                    <label>ID</label>
                    <Form.Input
                        value={medicine.id}
                        onChange={evt => update("id", evt.target.value)}
                        placeholder='id'
                    />
                </Form.Field>
                <Form.Field error={validation.name}>
                    <label>Name</label>
                    <Form.Input
                        value={medicine.name}
                        onChange={evt => update("name", evt.target.value)}
                        placeholder='Name'
                    />
                </Form.Field>
                <Form.Field error={validation.container_type}>
                    <label>Container type</label>
                    <Dropdown
                        placeholder='Container type'
                        fluid
                        selection
                        value={medicine.container_type}
                        options={["ampul"].map(it => ({ key: it, value: it, text: it }))}
                        onChange={(_, data) => update("container_type", data.value)}
                    />
                </Form.Field>
                <Form.Field error={validation.volume_ml}>
                    <label>Volume (ml)</label>
                    <Form.Input
                        value={medicine.volume_ml}
                        onChange={evt => update("volume_ml", evt.target.value)}
                        placeholder='Volume (ml)'
                    />
                </Form.Field>
                <Form.Field error={validation.limit_amount}>
                    <label>Limit</label>
                    <Form.Input
                        value={medicine.limit_amount}
                        onChange={evt => update("limit_amount", evt.target.value)}
                        placeholder='Limit'
                    />
                </Form.Field>
                <Form.Field error={validation.limit_period_hours}>
                    <label>Limit period</label>
                    <Form.Input
                        value={medicine.limit_period_hours}
                        onChange={evt => update("limit_period_hours", evt.target.value)}
                        placeholder='Limit period'
                    />
                </Form.Field>
                <Form.Field>
                        <label>Upload image (max 1 MB)</label>
                        <Grid columns={8}>
                            <Grid.Column width={6}>
                                <input
                                    accept="image/*"
                                    id="raised-button-file"
                                    type="file"
                                    onChange={(event) => setUploadFile(event.target.files !== null ? event.target.files[0] : undefined)}
                                />  
                            </Grid.Column>
                            <Grid.Column width={2}>
                                {medicine.image_name && <AuthenticatedImage width={200} url={getMedicineImageUrl(medicine.id)} fallbackUrl="/public/logo192.png" wrapped ui={false} />}
                            </Grid.Column>
                        </Grid>
                    </Form.Field>
                <Button type='submit' onClick={submit}>Submit</Button>
                {message && <Message negative>{message}</Message>}
            </Form>
        </Grid>
    )
}