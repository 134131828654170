import React from 'react';
import useSWR from 'swr';
import { useUser } from '../../context/auth';
import { fetchWithToken } from '../../utils/fetch';
import { Grid, Header, Loader, Table } from 'semantic-ui-react';

export default function LogTable({url, title, headers, populateBody}) {
    const { user } = useUser();
    const { data } = useSWR(url, fetchWithToken(user.token));

    if (!data) {
        return <Loader active />;
    }

    return (
        <Grid container columns={1} style={{marginTop: 10}} textAlign={"left"}>
            <Header>{title}</Header>
            <Table celled style={{padding: 0}}>
                <Table.Header>
                    <Table.Row>
                        {headers.map(it => <Table.HeaderCell key={it}>{it}</Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {populateBody(data)}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        {headers.map(it => <Table.HeaderCell key={it}>{it}</Table.HeaderCell>)}
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Grid>
    )
}