import React from "react";
import { Link } from "react-router-dom";
import { Button, List, Loader } from "semantic-ui-react";
import { useMedicineConfig } from "../../context/medicine_configuration";

export function MedicineTypesList() {
    const { medicine } = useMedicineConfig();

    if (!medicine) {
        return <Loader active />;
    }

    return (
        <List divided relaxed>
            {medicine.map(it => (
                <List.Item key={it.id}>
                    <List.Content floated='right'>
                        <Link to={`/medicine/${it.id}`}>
                            <Button icon="pencil" basic color="green" floated='right' size="mini" />
                        </Link>
                    </List.Content>
                    <List.Icon icon="user" />
                    <List.Content>
                        <List.Header as="b">{it.name}</List.Header>
                        <List.Description>{it.container_type}, {it.volume_ml} ml</List.Description>
                    </List.Content>
                </List.Item>
            ))}
        </List>
    )
}