const API_URL = window._env_.API_BASE_URL;

function toJson(res) {
    if (res.status === 200) {
        return res.json();
    } else if (res.status > 299) {
        return res.json().then(err => {
            throw err;
        });
    }
}

export async function performLogin(email, pin) {
    const res = await fetch(`${API_URL}/v1/login/email`, {
        method: 'POST',
        body: JSON.stringify({email, pin}),
        headers: {
            "Content-Type": "application/json"
        },
    });

    return toJson(res);
}

export async function saveUser(token, user) {
    const method = user.id ? 'PUT' : 'POST';
    const url = user.id ? `${API_URL}/v1/user/${user.id}` : `${API_URL}/v1/user`;
    const res = await fetch(url, {
        method: method,
        body: JSON.stringify({ ...user, id: undefined }),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    });

    return toJson(res);
}

export async function saveMachine(token, machine) {
    const method = 'POST';
    const url = `${API_URL}/v1/machine`;
    const res = await fetch(url, {
        method: method,
        body: JSON.stringify(machine),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    });

    return toJson(res);
}

export async function saveCartridge(token, cartridge, updating) {
    const method = updating ? 'PUT' : 'POST';
    const url = updating ? `${API_URL}/v1/types/cartridge/${cartridge.id}` : `${API_URL}/v1/types/cartridge`;
    const body = {
        id : updating ? undefined : cartridge.id,
        medicine_type_id: cartridge.medicine_type_id,
        capacity: cartridge.capacity,
        rows: cartridge.rows
    }
    const res = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    });

    return toJson(res);
}

export async function saveMedicine(token, medicine, updating) {
    const method = updating ? 'PUT' : 'POST';
    const url = updating ? `${API_URL}/v1/types/medicine/${medicine.id}` : `${API_URL}/v1/types/medicine`;
    const body = {
        id : updating ? undefined : medicine.id,
        name: medicine.name,
        container_type: medicine.container_type,
        volume_ml: medicine.volume_ml,
        limit_amount: medicine.limit_amount,
        limit_period_hours: medicine.limit_period_hours,
    }
    const res = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    });

    return toJson(res);
}

export async function performOverride(token, userId, override) {
    const res = await fetch(`${API_URL}/v1/user/${userId}/override`, {
        method: "POST",
        body: JSON.stringify(override),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    });

    return toJson(res);
}

export const getMachineListUrl = () => `${API_URL}/v1/machine`;
export const getMachineSlotsUrl = (id) => `${API_URL}/v1/machine/${id}/slots`;
export const getUserListUrl = () => `${API_URL}/v1/user`;
export const getDepartmentListUrl = () => `${API_URL}/v1/user/department`;
export const getUserLimitsUrl = () => `${API_URL}/v1/user/limits`;
export const getUserByIdUrl = (id) => `${API_URL}/v1/user/${id}`;
export const getTypesListUrl = () => `${API_URL}/v1/types`;
export const getMedicineImageUrl = (id) => `${API_URL}/v1/types/medicine/${id}/image`;

export const getAuditLogUrl = () => `${API_URL}/v1/log/user_login`;
export const getCartridgeLoadLogUrl = () => `${API_URL}/v1/log/load_cartridge`;
export const getDispenseLogUrl = () => `${API_URL}/v1/log/dispense`;
export const getOrderLogUrl = () => `${API_URL}/v1/log/order_completed`;

export const getStatsDispenseUrl = () => `${API_URL}/v1/stats/dispense`;