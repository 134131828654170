import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Grid, Message } from "semantic-ui-react";
import useSWR from "swr";
import { useUser } from "../../context/auth";
import { getUserByIdUrl, saveUser } from "../../services/api";
import { fetchWithToken } from "../../utils/fetch";

const ROLES = ["admin", "override", "machine", "service"];

export function UserFormPage({ match }) {
    return <UserForm id={match.params.id} />;
}

export function UserForm({ id }) {
    const { user: loggedInUser } = useUser();
    const history = useHistory();
    const [message, setMessage] = useState();

    const [user, setUser] = useState({ roles: [] });
    const [validation, setValidation] = useState({});

    const { data } = useSWR(id ? getUserByIdUrl(id) : null, fetchWithToken(loggedInUser.token));

    useEffect(() => {
        if (data) {
            const { user } = data;
            user.pin = undefined;
            setUser(user);
        }
    }, [data]);

    function update(field, value) {
        const u = { ...user };
        u[field] = value;
        setUser(u);
    }

    function submit() {
        let validationErrors = {};

        if (!user.name) {
            validationErrors.name = true;
        }
        if (!user.email) {
            validationErrors.email = true;
        }
        if (!user.tag) {
            validationErrors.tag = true;
        }
        if (!user.pin && !user.id) {
            validationErrors.pin = true;
        }

        setValidation(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            saveUser(loggedInUser.token, user)
                .then(() => {
                    history.push("/");
                }).catch(res => {
                    setMessage(res.errors.message);
                });
        }
    }

    return (
        <Grid container columns={1} textAlign={"left"}>
            <Form>
                <Form.Field error={validation.name}>
                    <label>Name</label>
                    <Form.Input
                        value={user.name}
                        onChange={evt => update("name", evt.target.value)}
                        placeholder='Name'
                    />
                </Form.Field>
                <Form.Field error={validation.email}>
                    <label>Email</label>
                    <Form.Input
                        value={user.email}
                        onChange={evt => update("email", evt.target.value)}
                        placeholder='Email'
                    />
                </Form.Field>
                <Form.Field error={validation.tag}>
                    <label>Tag ID</label>
                    <Form.Input
                        value={user.tag}
                        onChange={evt => update("tag", evt.target.value)}
                        placeholder='Tag ID'
                    />
                </Form.Field>
                <Form.Field>
                    <label>Department</label>
                    <Form.Input
                        value={user.department}
                        onChange={evt => update("department", evt.target.value)}
                        placeholder='Department'
                    />
                </Form.Field>
                <Form.Field error={validation.pin}>
                    <label>Pin</label>
                    <Form.Input
                        value={user.pin}
                        onChange={evt => update("pin", evt.target.value)}
                        placeholder='Pin'
                        type="password"
                    />
                </Form.Field>
                <Form.Field>
                    <label>Roles</label>
                    <Dropdown
                        placeholder='Roles'
                        fluid
                        multiple
                        selection
                        value={user.roles}
                        options={ROLES.map(it => ({ key: it, value: it, text: it }))}
                        onChange={(_, data) => update("roles", data.value)}
                    />
                </Form.Field>
                <Button type='submit' onClick={submit}>Submit</Button>
                {message && <Message negative>{message}</Message>}
            </Form>
        </Grid>
    )
}