import './App.css';
import LoginForm from './components/Login';
import { useUser } from './context/auth';
import Home from './components/Home';
import { UserForm, UserFormPage } from './components/user/UserForm';
import { Link, Route, Switch } from 'react-router-dom';
import { Grid, Image, Menu } from 'semantic-ui-react';
import { useState } from 'react';
import Configuration from './components/Configuration';
import AuditLog from './components/log/AuditLog';
import Logs from './components/log/Logs';
import CartridgeLoadLog from './components/log/CartridgeLoadLog';
import OrderLog from './components/log/OrderLog';
import DispenseLog from './components/log/DispenseLog';
import { MachineForm } from './components/machine/MachineForm';
import { OTP } from './components/OTP';
import { CartridgeTypeForm, CartridgeTypeFormPage } from './components/medicine/CartridgeTypeForm';
import { MedicineTypeForm, MedicineTypeFormPage } from './components/medicine/MedicineTypeForm';
import { UserLimitsPage } from './components/user/UserLimits';
import DispenseStats from './components/stats/DispenseStats';
import Stats from './components/stats/Stats';

export default function App() {

  const { user } = useUser();

  if (!user) {
    return <LoginForm />
  } else {
    return (
      <>
        <TopMenu />
        <Switch>
          <Route exact path={"/"} component={Home} />
          <Route exact path={"/config"} component={Configuration} />
          <Route exact path={"/otp"} component={OTP} />

          <Route exact path={"/machine/add"} component={MachineForm} />
          <Route exact path={"/cartridge/add"} component={CartridgeTypeForm} />
          <Route exact path={"/cartridge/:id"} component={CartridgeTypeFormPage} />
          <Route exact path={"/medicine/add"} component={MedicineTypeForm} />
          <Route exact path={"/medicine/:id"} component={MedicineTypeFormPage} />

          <Route exact path={"/user/add"} component={UserForm} />
          <Route exact path={"/user/:id"} component={UserFormPage} />
          <Route exact path={"/user/:id/limit"} component={UserLimitsPage} />

          <Route exact path={"/log"} component={Logs} />
          <Route exact path={"/log/audit"} component={AuditLog} />
          <Route exact path={"/log/cartridge"} component={CartridgeLoadLog} />
          <Route exact path={"/log/dispense"} component={DispenseLog} />
          <Route exact path={"/log/orders"} component={OrderLog} />

          <Route exact path={"/stats"} component={Stats} />
          <Route exact path={"/stats/dispense"} component={DispenseStats} />
        </Switch>
      </>
    );

  }
}

const MENU_ITEMS = [
  {
    name: "PreVender",
    link: "/"
  },
  {
    name: "Stats",
    link: "/stats/dispense"
  },
  {
    name: "Logs",
    link: "/log"
  },
  {
    name: "Configuration",
    link: "/config"
  },
  {
    name: "One-Time Passwords",
    link: "/otp"
  }
]

function TopMenu() {
  const [item, setItem] = useState();
  function select(e) {
    setItem(e.target.name || "reviews");
  }
  return (
    <div className="App-header">
      <Menu fixed="top" inverted className={"menuBar"}>
        <Menu.Item as='p' header>
          <Image size='mini' src='/img/logo_notext.png' style={{ marginRight: '1.5em' }} />
        </Menu.Item>
        {MENU_ITEMS.map(it => (
          <Link
            key={it.name}
            className={`item ${item === it.name ? 'active' : ''}`}
            name={it.name}
            onClick={select}
            to={it.link}
          >
            {it.name}
          </Link>
        ))}
      </Menu>
    </div>
  )
}
