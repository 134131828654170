import React from 'react';
import { getCartridgeLoadLogUrl } from '../../services/api';
import { Table } from 'semantic-ui-react';
import LogTable from './LogTable';

export default function CartridgeLoadLog() {

    return (
        <LogTable
            title="Cartridge load log"
            url={getCartridgeLoadLogUrl()}
            headers={["ID", "User", "Machine", "Slot", "Medicine", "Initial amount", "Timestamp"]}
            populateBody={data => data.map(it => (
                <Table.Row key={it.id}>
                    <Table.Cell>{it.id}</Table.Cell>
                    <Table.Cell>{it.user_id}</Table.Cell>
                    <Table.Cell>{it.machine_id}</Table.Cell>
                    <Table.Cell>{it.slot_number}</Table.Cell>
                    <Table.Cell>{it.cartridge_type_id}</Table.Cell>
                    <Table.Cell>{it.initial_amount}</Table.Cell>
                    <Table.Cell>{it.timestamp}</Table.Cell>
                </Table.Row>
            ))}
        />);
}