import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Grid, Message } from "semantic-ui-react";
import { useUser } from "../../context/auth";
import { saveMachine } from "../../services/api";

export function MachineForm() {
    const { user } = useUser();
    const history = useHistory();
    const [message, setMessage] = useState();

    const [machine, setMachine] = useState();
    const [validation, setValidation] = useState({});

    function update(field, value) {
        const u = { ...machine };
        u[field] = value;
        setMachine(u);
    }

    function submit() {
        let validationErrors = {};

        if (!machine.id) {
            validationErrors.id = true;
        }
        if (!machine.location) {
            validationErrors.location = true;
        }
        if (!machine.password) {
            validationErrors.password = true;
        }

        setValidation(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            saveMachine(user.token, machine)
                .then(() => {
                    history.push("/");
                }).catch(res => {
                    console.log(res);
                    setMessage(res?.errors?.message || "An unknown error occurred");
                });
        }
    }

    return (
        <Grid container columns={1} textAlign={"left"}>
            <Form>
                <Form.Field error={validation.id}>
                    <label>ID</label>
                    <Form.Input
                        value={user.id}
                        onChange={evt => update("id", evt.target.value)}
                        placeholder='ID'
                    />
                </Form.Field>
                <Form.Field error={validation.location}>
                    <label>Location</label>
                    <Form.Input
                        value={user.location}
                        onChange={evt => update("location", evt.target.value)}
                        placeholder='Location'
                    />
                </Form.Field>
                <Form.Field error={validation.pin}>
                    <label>Password</label>
                    <Form.Input
                        value={user.password}
                        onChange={evt => update("password", evt.target.value)}
                        placeholder='Password'
                        type="password"
                    />
                </Form.Field>
                <Button type='submit' onClick={submit}>Submit</Button>
                {message && <Message negative>{message}</Message>}
            </Form>
        </Grid>
    )
}