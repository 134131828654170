export function fetchWithToken(
    token
) {
    return async function(input, init, method="GET", body=undefined) {
        if (!init) {
            init = {headers: {}};
        }

        return fetch(input, {
            ...init,
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                ...init.headers,
                "Authorization": `Bearer ${token}`,
                "Content-Type": body ? "application/json" : undefined,
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then(err => {
                    throw err;
                });
            }
        });
    }
}
