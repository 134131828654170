import React from 'react';
import { getAuditLogUrl } from '../../services/api';
import { Icon, Table } from 'semantic-ui-react';
import LogTable from './LogTable';

export default function AuditLog() {
    return (
        <LogTable
            title="Audit log"
            url={getAuditLogUrl()}
            headers={["ID", "User", "Action", "Tag", "Success", "Timestamp"]}
            populateBody={data => data.map(it => (
                <Table.Row key={it.id}>
                    <Table.Cell>{it.id}</Table.Cell>
                    <Table.Cell>{it.user_id}</Table.Cell>
                    <Table.Cell>{it.action}</Table.Cell>
                    <Table.Cell>{JSON.parse(it.result || "{}").tag}</Table.Cell>
                    <Table.Cell>
                        {JSON.parse(it.result || "{}").success && <Icon name="check" color="green" />}
                        {!JSON.parse(it.result || "{}").success && <Icon name="close" color="red" />}
                    </Table.Cell>
                    <Table.Cell>{it.timestamp}</Table.Cell>
                </Table.Row>
            ))}
        />);
}