import React, {createContext, useContext, useEffect, useState} from "react";
import useSWR from "swr";
import { getTypesListUrl, getUserLimitsUrl } from "../services/api";
import { fetchWithToken } from "../utils/fetch";
import { useUser } from "./auth";

export const MedicineContext = createContext({});
export const useMedicineConfig = () => useContext(MedicineContext);

export const MedicineConfigProvider = ({children}) => {
    const {user} = useUser();

    const { data, mutate } = useSWR(user?.token ? getTypesListUrl() : null, fetchWithToken(user?.token));
    const { data: limits, mutate: mutateLimits } = useSWR(user?.token ? getUserLimitsUrl() : null, fetchWithToken(user?.token));

    const [medicine, setMedicine] = useState();
    const [cartridges, setCartridges] = useState();

    const [medicineMap, setMedicineMap] = useState({});
    const [cartridgesMap, setCartridgesMap] = useState({});

    const [limitsMap, setLimitsMap] = useState({});

    function medicineFromCartridge(cartridgeId) {
        const cartridge = cartridgesMap[cartridgeId];
        if (cartridge) {
            return medicineMap[cartridge.medicine_type_id];
        }
        return null;
    }
    
    function refresh() {
        mutate(data, true);
        mutateLimits(limits, true);
    }

    function getLimits(userId, medicineTypeId) {
        const user = limitsMap[userId];
        if (user) {
            if (medicineTypeId) {
                return user[medicineTypeId];
            } else {
                return user;
            }
        }
        return undefined;
    }

    useEffect(() => {
        if (data) {
            const { medicine, cartridges } = data;
            setMedicine(medicine);
            setCartridges(cartridges);

            const medicineMap = medicine.reduce((map, med) => {
                map[med.id] = med;
                return map;
            }, {});
            setMedicineMap(medicineMap);
            const cartridgesMap = cartridges.reduce((map, med) => {
                map[med.id] = med;
                return map;
            }, {});
            setCartridgesMap(cartridgesMap);
        }
    }, [data]);

    useEffect(() => {
        if (limits?.limit) {
            const limitsMap = limits.limit.reduce((map, limit) => {
                if (!(limit.user_id in map)) {
                    map[limit.user_id] = {};
                }
                map[limit.user_id][limit.medicine_type_id] = limit;
                return map;
            }, {});
            setLimitsMap(limitsMap);
        }
    }, [limits]);

    return (
        <MedicineContext.Provider value={{
            medicine, cartridges, 
            medicineMap, cartridgesMap, 
            limitsMap, getLimits,
            medicineFromCartridge, 
            setMedicine, setCartridges, 
            refresh}}>
            {children}
        </MedicineContext.Provider>
    );
};