import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getMachineListUrl, getStatsDispenseUrl, getDepartmentListUrl, getUserListUrl } from '../../services/api';
import { Accordion, Checkbox, Container, Dropdown, Form, Grid, Header, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { useUser } from '../../context/auth';
import useSWR from 'swr';
import { fetchWithToken } from '../../utils/fetch';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { ComposedChart, ReferenceLine, Line, Bar, XAxis, YAxis, Legend, Tooltip, CartesianGrid } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { useResize } from '../../utils/resize';


const colors = scaleOrdinal(schemeCategory10).range();

function ConfigurationElement({ title, children }) {

    const [active, setActive] = useState(true);

    return (
        <Accordion>
            <Accordion.Title
                active={active}
                onClick={() => setActive(!active)}
            >
                <Icon name='dropdown' />
                {title}
            </Accordion.Title>
            <Accordion.Content
                active={active}
            >
                {children}
            </Accordion.Content>
        </Accordion>
    )
}

export default function DispenseStats() {

    const [start, setStart] = useState(new Date(new Date() - 30 * 24 * 60 * 60 * 1000));
    const [end, setEnd] = useState(new Date());
    const [period, setPeriod] = useState("month");
    const [groupByFields, setGroupByFields] = useState(["department"]);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [showMedian, setShowMedian] = useState(false);
    const [showAverage, setShowAverage] = useState(false);
    const [showIQR, setShowIQR] = useState(false);

    const componentRef = useRef();
    const { width, height } = useResize(componentRef);

    const [keys, setKeys] = useState([]);
    const [rows, setRows] = useState([]);
    const [stats, setStats] = useState({ average: 0, median: 0, iqr25: 0, iqr75: 0 });
    const [dateRange, setDateRange] = useState([0, 1]);

    const { user } = useUser();
    const { data, error } = useSWR(
        [getStatsDispenseUrl(), start, end, period, groupByFields, selectedMachines, selectedDepartments, selectedUsers],
        (url, start, end, period, groupByFields, selectedMachines, selectedDepartments, selectedUsers) => fetchWithToken(user.token)(
            url,
            null,
            "POST",
            {
                start,
                end,
                period,
                groupByFields,
                machines: selectedMachines.length > 0 ? selectedMachines : undefined,
                departments: selectedDepartments.length > 0 ? selectedDepartments : undefined,
                users: selectedUsers.length > 0 ? selectedUsers : undefined,
            }
        ));

    const { data: availableMachines } = useSWR(getMachineListUrl(), fetchWithToken(user.token));
    const { data: availableDepartments } = useSWR(getDepartmentListUrl(), fetchWithToken(user.token));
    const { data: availableUsers } = useSWR(getUserListUrl(), fetchWithToken(user.token));

    const formattedDate = useCallback((value) => {
        const date = new Date(value.getTime());
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        if (period === "month") {
            date.setDate(1);
        } else if (period === "year") {
            date.setDate(1);
            date.setMonth(0);
        }
        console.log(value, date);
        return date.getTime();
    }, [period]);

    useEffect(() => {
        if (data) {
            setKeys(data.keys);
            setRows(data.rows);
            setStats({ average: data.average, median: data.median, iqr25: data.iqr25, iqr75: data.iqr75 });
        }
    }, [data]);
    useEffect(() => {
        setDateRange([formattedDate(start) - 24 * 60 * 60 * 1000, formattedDate(end) + 24 * 60 * 60 * 1000]);
    }, [start, end, formattedDate]);

    const timeFormatter = t => new Date(t).toISOString().substring(0, period === "day" ? 10 : (period === "month" ? 7 : 4));

    return (
        <Container fluid style={{padding: 20}}>
            <Grid>
                <Grid.Column widescreen={12} computer={12} tablet={12} mobile={16}>
                    <div ref={componentRef}>
                        {keys.length === 0 &&
                            <Segment placeholder style={{height: width/2}}>
                                <Header icon>
                                    <Icon name='search' />
                                    No data in selected period.
                                </Header>
                            </Segment>
                        }
                        {keys.length > 0 &&
                            <ComposedChart
                                width={width}
                                height={width/2}
                                data={rows}
                                barSize={15}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                            >
                                <XAxis
                                    dataKey="date"
                                    scale="time"
                                    type="number"
                                    domain={dateRange}
                                    tickFormatter={timeFormatter}
                                    angle={period === "day" ? 270 : 0}
                                    height={period === "day" ? 100 : 30}
                                    tickMargin={period === "day" ? 45 : 10}
                                    interval={0}
                                />
                                <CartesianGrid vertical={false} />

                                <YAxis yAxisId="a" />
                                {keys.map((key, idx) => (
                                    <Bar key={key} dataKey={key} yAxisId="a" fill={colors[idx]} />
                                ))}

                                {keys.length > 1 &&
                                    <Line dataKey="average" type="monotone" yAxisId="a" />
                                }

                                {showIQR &&
                                    <ReferenceLine yAxisId="a" y={stats.iqr25} label="25th percentile" stroke="green" />
                                }
                                {showIQR &&
                                    <ReferenceLine yAxisId="a" y={stats.iqr75} label="75th percentile" stroke="green" />
                                }
                                {showAverage &&
                                    <ReferenceLine yAxisId="a" y={stats.average} label="Average" stroke="red" />
                                }
                                {showMedian &&
                                    <ReferenceLine yAxisId="a" y={stats.median} label="Median" stroke="orange" />
                                }

                                <Tooltip labelFormatter={timeFormatter} />
                                <Legend verticalAlign="top" height={36} />
                            </ComposedChart>
                        }
                    </div>
                </Grid.Column>
                <Grid.Column widescreen={4} computer={4} tablet={4} mobile={16}>
                    <Form>
                        <ConfigurationElement title="Period">

                            <Form.Field className="date">
                                <label>Start</label>
                                <SemanticDatepicker
                                    datePickerOnly
                                    placeholder={"Start"}
                                    value={start}
                                    onChange={(_, data) => setStart(data.value)}
                                />
                            </Form.Field>
                            <Form.Field className="date">
                                <label>End</label>
                                <SemanticDatepicker
                                    datePickerOnly
                                    placeholder={"End"}
                                    value={end}
                                    onChange={(_, data) => setEnd(data.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Period</label>
                                <Dropdown
                                    placeholder='Period'
                                    fluid
                                    selection
                                    value={period}
                                    options={["day", "month", "year"].map(it => ({ key: it, value: it, text: `${it[0].toUpperCase()}${it.substring(1)}` }))}
                                    onChange={(_, data) => setPeriod(data.value)}
                                />
                            </Form.Field>
                        </ConfigurationElement>
                        <ConfigurationElement title="Group by">
                            <Form.Field>
                                <Dropdown
                                    placeholder='Group by'
                                    fluid
                                    selection
                                    multiple
                                    value={groupByFields}
                                    options={["user_id", "medicine_type_id", "department", "machine_id"].map(it => ({ key: it, value: it, text: it }))}
                                    onChange={(_, data) => setGroupByFields(data.value)}
                                />
                            </Form.Field>
                        </ConfigurationElement>
                        <ConfigurationElement title="Machines">
                            <Form.Field>
                                <Dropdown
                                    placeholder='Machines'
                                    fluid
                                    selection
                                    multiple
                                    value={selectedMachines}
                                    options={(availableMachines?.machines || []).map(it => ({ key: it.id, value: it.id, text: it.id }))}
                                    onChange={(_, data) => setSelectedMachines(data.value)}
                                />
                            </Form.Field>
                        </ConfigurationElement>
                        <ConfigurationElement title="Departments">
                            <Form.Field>
                                <Dropdown
                                    placeholder='Departments'
                                    fluid
                                    selection
                                    multiple
                                    value={selectedDepartments}
                                    options={(availableDepartments || []).map(it => ({ key: it, value: it, text: it }))}
                                    onChange={(_, data) => setSelectedDepartments(data.value)}
                                />
                            </Form.Field>
                        </ConfigurationElement>
                        <ConfigurationElement title="Users">
                            <Form.Field>
                                <Dropdown
                                    placeholder='Users'
                                    fluid
                                    selection
                                    multiple
                                    value={selectedUsers}
                                    options={(availableUsers || []).filter(it => {
                                        if (it.roles.indexOf("machine") > -1) {
                                            return false;
                                        }

                                        if (selectedDepartments.length > 0 && selectedDepartments.indexOf(it.department) === -1) {
                                            // don't show user from departments that are not selected (if any departments are selected)
                                            return false;
                                        }

                                        return true;
                                    }).map(it => ({ key: it.id, value: it.id, text: `${it.name} (${it.department})` }))}
                                    onChange={(_, data) => setSelectedUsers(data.value)}
                                />
                            </Form.Field>
                        </ConfigurationElement>
                        <ConfigurationElement title="Statistics">
                            <Form.Field>
                                <Checkbox
                                    checked={showAverage}
                                    onChange={(_, data) => setShowAverage(data.checked)}
                                    label='Average'
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    checked={showMedian}
                                    onChange={(_, data) => setShowMedian(data.checked)}
                                    label='Median'
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    checked={showIQR}
                                    onChange={(_, data) => setShowIQR(data.checked)}
                                    label='IQR'
                                />
                            </Form.Field>
                        </ConfigurationElement>
                    </Form>
                </Grid.Column>
            </Grid>
        </Container>
    );
}