import React from "react";
import { Link } from "react-router-dom";
import { Button, Header, List, Loader, Popup } from "semantic-ui-react";
import useSWR from "swr";
import { useUser } from "../../context/auth";
import { getUserListUrl } from "../../services/api";
import { fetchWithToken } from "../../utils/fetch";

export function UserList() {
    const { user } = useUser();

    const { data: users } = useSWR(getUserListUrl(), fetchWithToken(user.token));

    if (!users) {
        return <Loader active />;
    }

    function renderList(filter) {
        return (
            <List divided relaxed>
                {users.filter(filter).map(it => (
                    <List.Item key={it.id}>
                        <List.Content floated='right'>
                            <Link to={`/user/${it.id}`}>
                                <Popup
                                    content="Edit user"
                                    position="right center"
                                    trigger={
                                        <Button icon="pencil" basic color="green" floated='right' size="mini" />
                                    } />
                            </Link>
                            <Link to={`/user/${it.id}/limit`}>
                                <Popup
                                    content="Limitations"
                                    position="left center"
                                    trigger={
                                        <Button icon="resize horizontal" basic color="yellow" floated='right' size="mini" />
                                    } />
                            </Link>
                        </List.Content>
                        <List.Icon icon="user" />
                        <List.Content>
                            <List.Header as="b">{it.name} {it.department && `(${it.department})`}</List.Header>
                            <List.Description><b>Roles:</b> {it.roles.join(", ")}</List.Description>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        );
    }

    return (
        <>
            <Header>Users</Header>
            {renderList(it => it.roles.indexOf("machine") === -1)}
            <Header>Machines</Header>
            {renderList(it => it.roles.indexOf("machine") > -1)}
        </>
    )
}