import React, { useEffect, useState } from 'react';
import { Card, Item, List, Loader, Progress, Segment } from "semantic-ui-react";
import useSWR from 'swr';
import { useUser } from "../../context/auth";
import { useMedicineConfig } from '../../context/medicine_configuration';
import { getMachineSlotsUrl } from '../../services/api';
import { fetchWithToken } from '../../utils/fetch';

export default function Machine({ machine }) {
    const { user: loggedInUser } = useUser();
    const { medicineFromCartridge } = useMedicineConfig();

    //const { data: slots } = useSWR(getMachineSlotsUrl(machine.id), fetchWithToken(loggedInUser.token));

    /*const [total, setTotal] = useState(0);
    useEffect(() => {
        if (slots) {
            const total = slots.reduce((i, slot) => i + slot.current_quantity, 0);
            setTotal(total);
        }
    }, [slots]);*/


    function renderSlot(slot) {
        const medicine = medicineFromCartridge(slot.cartridge_type_id);
        const percent = Math.round(slot.current_quantity / slot.capacity * 100, 0);
        return (
            <Card.Content extra>
                <Card.Description>
                    <Progress percent={percent} progress success={percent >= 50} warning={percent >= 20 && percent < 50} error={percent < 20}>
                        {medicine?.name} {medicine?.volume_ml} ml
                    </Progress>
                </Card.Description>
            </Card.Content>
        );
    }

    if (!medicineFromCartridge) {
        return <Loader active />;
    }

    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    {machine.id}
                </Card.Header>
            </Card.Content>

            {machine.loaded_medicine?.map(it => renderSlot(it))}
        </Card>
    )

}
/*

        <Item>
            <Item.Content>
                <Segment>
                    <Item.Header as="h3">
                        {machine.id}
                    </Item.Header>
                    <Item.Description>
                        {!slots && <Loader active />}
                        <List>
                            {slots?.map(it => renderSlot(it))}
                        </List>
                    </Item.Description>
                    <Item.Meta>Total: {total}</Item.Meta>
                </Segment>
            </Item.Content>
        </Item>
        */