import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header } from "semantic-ui-react";
import { CartridgesTypesList } from "./medicine/CartridgeTypesList";
import { MedicineTypesList } from "./medicine/MedicineTypesList";
import { UserList } from './user/UserList';

export default function Configuration() {
    return (
        <Grid container columns={2} textAlign={"left"}>
            <Grid.Row>
                <Grid.Column>
                    <Header>Medicine types</Header>
                    <MedicineTypesList />
                    <Link to="/medicine/add">
                        <Button icon="plus" content="Add new medicine type" />
                    </Link>

                    <Header>Cartridge types</Header>
                    <CartridgesTypesList />
                    <Link to="/cartridge/add">
                        <Button icon="plus" content="Add new cartridge type" />
                    </Link>
                </Grid.Column>
                <Grid.Column>
                    <UserList />
                    <Link to="/user/add">
                        <Button icon="plus" content="Add new user" />
                    </Link>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}