import React, {useState} from "react";
import {Button, Form, Grid, Header, Image, Message, Segment} from 'semantic-ui-react';
import { useUser } from "../context/auth";

const LoginForm = (props) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const {login} = useUser();

  async function performLogin() {
    setLoading(true);
    login(username, password, result => {
        setLoading(false);
        if (!result.success) {
            setError(result.message);
        }
    });
  }

  return (
    <Grid className={"loginPage"} textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
      <Grid.Column style={{maxWidth: 450}}>
        <Header as='h1' textAlign='center'>
          <Image src='/img/logo.png' style={{width: 250}}/>
        </Header>
        <Form size='large'>
          <Segment stacked>
            <Form.Input value={username} onChange={(e) => setUsername(e.target.value)} 
              fluid 
              icon='user' 
              iconPosition='left' 
              placeholder={"E-mail"}
            />
            <Form.Input value={password} onChange={(e) => setPassword(e.target.value)}
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={"Password"}
              type='password'
            />

            <Button primary loading={loading} onClick={performLogin} fluid size='large'>
              {"Login"}
            </Button>
          </Segment>
        </Form>
        {error &&<Message error visible>
          {error}
        </Message>}
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;