import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header } from "semantic-ui-react";
import { MachineList } from "./machine/MachineList";

export default function Home() {
    return (
        <Grid container columns={1} textAlign={"left"}>
            <Grid.Row>
                <Grid.Column>
                    <Header>Machines</Header>
                    <MachineList />
                    <div style={{ paddingTop: 20 }}>
                        <Link to="/machine/add">
                            <Button icon="plus" content="Add new machine" />
                        </Link>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}