import React, {createContext, useContext, useState} from "react";
import {performLogin} from "../services/api";

export const AuthContext = createContext({});
export const useUser = () => useContext(AuthContext);

export const UserProvider = ({children}) => {
    const [user, setUser] = useState();

    function login(tag, pin, onResult) {
        performLogin(tag, pin).then(res => {
            console.log("got user res", res);
            setUser(res);
            onResult({success: true});
        }).catch(res => {
            onResult({success: false, message: res.errors?.message});
        });
    }


    return (
        <AuthContext.Provider value={{user, login}}>
            {children}
        </AuthContext.Provider>
    );
};