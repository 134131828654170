import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function Stats() {

    return (
        <Grid container columns={2} textAlign={"left"}>
            <Grid.Row>
                <Grid.Column>
                    <List>
                        <List.Item>
                            <Link to="/stats/dispense">Dispense stats</Link>
                        </List.Item>
                    </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}