import React, { useEffect, useState } from "react";
import { Grid, Header, Progress } from "semantic-ui-react";
import { authenticator } from 'otplib';

function getSecondsUntilOTPChange() {
    return 30 - (new Date()).getSeconds() % 30;
}

export function OTP() {

    const [seconds, setSeconds] = useState(getSecondsUntilOTPChange());
    const [token, setToken] = useState("");

    function updateOTP() {
        const secret = 'MFRGGZDFMZTWQ2LK';
        const token = authenticator.generate(secret);
        setToken(token);
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            const current = seconds;

            setSeconds(getSecondsUntilOTPChange());

            if (current < 1 || current > 29) {
                updateOTP();
            }
        }, 1000);

        updateOTP();
        return () => {
            clearInterval(myInterval);
        };
    })

    return (
        <Grid container columns={1} textAlign={"center"} style={{ width: 600, marginTop: 20 }}>
            <Grid.Column width={8}>
                <Header as="h1">{token}</Header>
                <Progress percent={seconds / 30 * 100} error={seconds < 5} warning={seconds < 10} success={seconds >= 10} />
            </Grid.Column>
        </Grid>
    )
}