import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Loader, Message, Modal, Table } from "semantic-ui-react";
import useSWR from "swr";
import { useUser } from "../../context/auth";
import { useMedicineConfig } from "../../context/medicine_configuration";
import { getUserByIdUrl, performOverride } from "../../services/api";
import { fetchWithToken } from "../../utils/fetch";

function medicineToString(medicine) {
    return `${medicine.name}, ${medicine.volume_ml} ml (${medicine.container_type})`
}

export function UserLimitsPage({ match }) {
    const id = match.params.id;
    const { user: loggedInUser } = useUser();
    const [message, setMessage] = useState();
    const [validation, setValidation] = useState({});

    const { data: user } = useSWR(getUserByIdUrl(id), fetchWithToken(loggedInUser.token));
    const { getLimits, medicineMap, refresh } = useMedicineConfig();

    const limits = getLimits(id);

    const [override, setOverride] = useState();
    const [amount, setAmount] = useState();
    const [allowMinutes, setAllowMinutes] = useState();

    function submit() {
        let validationErrors = {};

        if (!amount) {
            validationErrors.amount = true;
        }
        if (!allowMinutes) {
            validationErrors.allowMinutes = true;
        }

        setValidation(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const body = { 
                amount, 
                allow_minutes: allowMinutes, 
                medicine_type_id: override.medicine_type_id 
            };
            performOverride(loggedInUser.token, id, body)
                .then(() => {
                    refresh();
                    setOverride(undefined);
                }).catch(res => {
                    setMessage(res.message);
                });
        }
    }

    if (!user || !limits) {
        return <Loader active />;
    }

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Medicine</Table.HeaderCell>
                        <Table.HeaderCell>Limit</Table.HeaderCell>
                        <Table.HeaderCell>Last dispense</Table.HeaderCell>
                        <Table.HeaderCell>Override</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.keys(limits).map((it, idx) => (
                        <Table.Row key={idx}>
                            <Table.Cell>{medicineToString(medicineMap[limits[it].medicine_type_id])}</Table.Cell>
                            <Table.Cell>{limits[it].limit}</Table.Cell>
                            <Table.Cell>{limits[it].latest_dispense}</Table.Cell>
                            <Table.Cell>{limits[it].latest_override}</Table.Cell>
                            <Table.Cell>
                                <Button onClick={() => setOverride(limits[it])} content="Override limit" />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {override && 
            <Modal
                onClose={() => setOverride(undefined)}
                open={!!override}
            >
                <Modal.Header>Override limit for {medicineToString(medicineMap[override.medicine_type_id])}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field error={validation.amount}>
                            <label>Amount</label>
                            <Form.Input
                                value={amount}
                                onChange={evt => setAmount(evt.target.value)}
                                placeholder='Amount'
                            />
                        </Form.Field>
                        <Form.Field error={validation.allowMinutes}>
                            <label>Expiry time (minutes)</label>
                            <Form.Input
                                value={allowMinutes}
                                onChange={evt => setAllowMinutes(evt.target.value)}
                                placeholder='Expiry time (minutes)'
                            />
                        </Form.Field>
                        <Button type='submit' onClick={() => submit()}>Submit</Button>
                        {message && <Message negative>{message}</Message>}
                    </Form>
                </Modal.Content>
            </Modal>
            }
        </>
    );
}