import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import { useUser } from "../context/auth";

export default function AuthenticatedImage({ url, fallbackUrl, ...rest }) {

    const { user } = useUser();
    const [src, setSrc] = useState("");

    useEffect(() => {
        if (user.token) {
            console.log(`fetching ${url}`);
            fetch(url, 
                {
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    }
                }
            )
            .then(r => {
                if (r.status < 300) {
                    return r.blob()
                } else {
                    throw new Error();
                }
            })
            .then(blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onload = function() {
                    var base64data = reader.result;
                    setSrc(`${base64data}`);
                }
            }).catch(e => {
                if (fallbackUrl) {
                    setSrc(fallbackUrl);
                }
            });
        }
    }, [url, fallbackUrl, user]);

    return (
        <Image src={src} alt="" {...rest} />
    );

}